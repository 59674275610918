<template>
  <v-card class="d-flex flex-column">
    <template #title>{{ $t(`${ticketType.toLowerCase()}s.create`) }}</template>

    <v-form v-model="valid" class="overflow-auto d-flex flex-column" @submit.prevent="() => onSubmit()">
      <v-card-text class="overflow-auto">
        <v-select
          v-if="showInspectionTypes"
          v-model="(formData as Inspection).data.inspectionType"
          style="margin-bottom: 6px;"
          :loading="typesPending"
          :label="$t('inspections.field.inspectionType') + ' *'"
          :items="inspectionTypes!"
          item-title="description"
          item-value="description"
          :rules="[(val) => !!val || $t('validation.required')]"
        />

        <ticket-general-form v-model="formData" :ticket-type="ticketType" :show-asset-field="!assetId" />
      </v-card-text>

      <v-divider />

      <v-card-actions class="flex-wrap justify-end">
        <v-btn color="primary" @click="() => onReset()">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" :loading="saving" type="submit">{{ $t('save') }}</v-btn>
        <v-btn color="primary" :loading="saving" type="submit" @click="() => (redirectAfterSave = true)">{{ $t('saveAndOpen') }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
import { TicketType } from "~~/stores/ticket.store"

const emit = defineEmits<{
  (e: "ok", newTicketId: string): void
  (e: "cancel"): void
}>()

const props = defineProps<{ assetId?: string; ticketType: TicketType; organizationId?: string }>()
const { assetId, organizationId, ticketType } = toRefs(props)

const $i18n = useI18n()
const $router = useRouter()

const mainStore = useMainStore()
const actionStore = useActionStore()
const inspectionStore = useInspectionStore()
const malfunctionStore = useMalfunctionStore()
const authStore = useAuthStore()
const organizationStore = useOrganizationStore()
const { currentOrganization, isSupplier, sourceOrganization, hasMultipleOrganizations } = storeToRefs(authStore)

const { data: inspectionTypes, pending: typesPending } = useLazyAsyncData(
  "inspection-types",
  async () => {
    if (ticketType.value !== TicketType.INSPECTION) return []

    return await inspectionStore.getTypes()
  },
  { default: () => [], watch: [ticketType] }
)

const valid = ref<boolean>()
const saving = ref(false)
const redirectAfterSave = ref(false)
const formData = ref<Partial<Ticket>>({
  type: ticketType.value,
  asset: assetId?.value,
  organization: organizationId?.value ?? (hasMultipleOrganizations.value ? undefined : currentOrganization.value),
  status: StatusDescription.NEW,
  data: ticketType.value === TicketType.INSPECTION ? { inspectionType: "BRL" } : ({} as any),
})

const showInspectionTypes = computed(() => ticketType.value === TicketType.INSPECTION && inspectionTypes.value?.filter(type => type.description !== "BRL").length)

const onSubmit = () => {
  if (!valid.value) { return }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    saving.value = true

    let newTicketId: string

    try {
      if (ticketType.value === TicketType.INSPECTION) {
        const data = { inspectionType: "BRL" }
        const inspection = await inspectionStore.createInspection( {
          asset: formData.value.asset!,
          date: formData.value.date,
          description: formData.value.description!,
          supplier: formData.value.supplier?._id,
          users: formData.value.users?.map((user) => user._id) ?? [],
          contract: formData.value.contract?._id,
          remark: formData.value.remark,
          data
        }) 
        newTicketId =  inspection._id
      } else if (ticketType.value === TicketType.ACTION) {
        const data = formData.value.data as ActionData | undefined
        const action = await actionStore.create({
          asset: formData.value.asset!,
          description: formData.value.description!,
          contract: formData.value.contract?._id,
          startAt: formData.value.startAt,
          endAt: formData.value.endAt,
          date: formData.value.date,
          invoicePeriod: formData.value.invoicePeriod,
          remark: formData.value.remark,
          supplier: formData.value.supplier?._id,
          users: formData.value.users?.map((u) => u._id) ?? [],
          data: {
            component: data?.component?._id,
            componentGroup: data?.componentGroup?._id,
            settlement: data?.settlement,
          },
        })

        newTicketId = action._id
      } else {
        const data = formData.value.data as MalfunctionData | undefined
        const malfunction = await malfunctionStore.create({
          asset: formData.value.asset!,
          description: formData.value.description!,
          contract: formData.value.contract?._id,
          startAt: formData.value.startAt,
          endAt: formData.value.endAt,
          date: formData.value.date,
          invoicePeriod: formData.value.invoicePeriod,
          remark: formData.value.remark,
          supplier: formData.value.supplier?._id,
          users: formData.value.users?.map((u) => u._id) ?? [],
          data: {
            component: data?.component?._id,
            componentGroup: data?.componentGroup?._id,
            cause: data?.cause?._id,
            solution: data?.solution?._id,
            settlement: data?.settlement,
          },
        })

        newTicketId = malfunction._id
      }
    } catch (error) {
      saving.value = false
      console.error(error)
      throw error
    }

    if (redirectAfterSave.value) {
      $router.push(`/tickets/${ticketType.value.toLowerCase()}s/${newTicketId}`)
    }

    saving.value = false
    emit('ok', newTicketId)
  })
}

const onReset = () => emit('cancel')

watch([isSupplier, sourceOrganization], async () => {
  if (formData.value.supplier) { return }

  if (isSupplier.value && sourceOrganization.value) {
    const { _id, description } = await organizationStore.getOrganization(sourceOrganization.value)
    formData.value.supplier = { _id, description }
  }
}, { immediate: true })
</script>
